<template>
   <div :class="'m-p profile-container ' + ( this.$store.state.profileSideBar ? 'open' : '' )">
      <Subscriptions />
   </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'
export default {
name: 'Packages Subscriptions',
components: {
   Subscriptions: defineAsyncComponent( () => import('@/components/Profile/Subscriptions.vue') ),
},
}
</script>